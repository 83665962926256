import React from 'react';
import Link from 'gatsby-link';
import * as style from './button.module.scss';
import LocationPin from './icons/locationPin';

const getColorClassname = (color, hidden) => {
  const hiddenClass = hidden ? style.hidden : '';
  switch (color) {
    case 'white':
      return `${style.whiteButton} ${hiddenClass}`;
    case 'black':
      return `${style.blackButton} ${hiddenClass}`;
    case 'blue':
      return `${style.blueButton} ${hiddenClass}`;
    case 'grey':
      return `${style.greyButton} ${hiddenClass}`;
    case 'whiteBorder':
      return `${style.whiteBorderButton} ${hiddenClass}`;
    case 'orange':
        return `${style.orangeButton} ${hiddenClass}`;
    case 'whiteSolid':
        return `${style.whiteSolidButton} ${hiddenClass}`;
    default:
      return `${style.blackButton} ${hiddenClass}`;
  }
};

const getClassnames = props =>
  `${getColorClassname(props.color, props.hidden)} ${
    props.noHover ? style.noHover : ''
  } ${props.isBlock ? style.isBlock : ''} ${
    props.morePadding ? style.morePadding : ''
  } ${props.icon ? style.hasIcon : ''}
  `;

const getStyle = props => {
  const style = {};
  if (props.maxWidth) {
    style.maxWidth = props.maxWidth;
  }
  return style;
};

const Button = props => {
  if (props.target === 'external') {
    return (
      <a
        href={props.to}
        className={getClassnames(props)}
        target="_blank"
        rel="noopener"
        style={getStyle(props)}
      >
        <span className={style.mainText}>{props.text}</span>
      </a>
    );
  } else if (
    typeof props.handleClick !== 'undefined' ||
    typeof props.handleDown !== 'undefined'
  ) {
    return (
      <button
        className={getClassnames(props)}
        onClick={props.handleClick ? props.handleClick : null}
        onMouseDown={props.handleDown ? props.handleDown : null}
        onTouchStart={props.handleDown ? props.handleDown : null}
        style={getStyle(props)}
      >
        <span className={style.mainText}>{props.text}</span>
        {props.icon === 'pin' && (
          <span className={style.icon}>
            <LocationPin />
          </span>
        )}
      </button>
    );
  } else {
    return (
      <Link
        className={getClassnames(props)}
        to={props.to}
        style={getStyle(props)}
      >
        <span className={style.mainText}>{props.text}</span>
        {props.icon === 'pin' && (
          <span className={style.icon}>
            <LocationPin />
          </span>
        )}
      </Link>
    );
  }
};

export default Button;
