import React, { useContext } from 'react';
import * as baseStyle from './faqList.module.scss';
import * as legacyStyle from './faqList.legacy.module.scss';
import jump from 'jump.js';
import ArticleThemeContext from '../../context/articleThemeContext';

const jumpToAnchor = (e, anchor) => {
  e.preventDefault();
  jump(anchor);
};
export const makeAnchor = text =>
  text.replace(/[^\w\s]|_/g, '').replace(/\s/g, '');

const FaqList = props => {
  const theme = useContext(ArticleThemeContext);
  const style = theme === 'dark' ? legacyStyle : baseStyle;

  return (
    <div
      className={`typeFonts1 typeColorDark1 ${theme === 'light' ? 'typeColorBlack' : ''} text-block ${style.faqListWrap}`}
      id="faqList"
    >
      <h2 className={`f-label ${style.listHeader}`}>Questions</h2>
      <ul className={style.faqList}>
        {props.paragraphs.map((p, i) => {
          if (p.type === 'section_heading') {
            return (
              <li key={`faq${i}`}>
                <a
                  href={`#${makeAnchor(p.section_heading)}`}
                  onClick={e => {
                    jumpToAnchor(e, `#${makeAnchor(p.section_heading)}`);
                  }}
                >
                  {p.section_heading}
                </a>
              </li>
            );
          }
          return null;
        })}
      </ul>
    </div>
  );
};

export default FaqList;
