// extracted by mini-css-extract-plugin
export var logo = "ourTeam-module--logo--FfVak";
export var spacer = "ourTeam-module--spacer--m-1h6";
export var toTopWrap = "ourTeam-module--toTopWrap--3-GvH";
export var opacity1 = "ourTeam-module--opacity-1--mgy3J";
export var toTopButton = "ourTeam-module--toTopButton--uzfsC";
export var toTopText = "ourTeam-module--toTopText--ofGFz";
export var ourTeamHeader = "ourTeam-module--ourTeamHeader--70Q-k";
export var sectionNavigationWrap = "ourTeam-module--sectionNavigationWrap--hffdk";
export var sectionNavigation = "ourTeam-module--section-navigation--ymWBx";
export var ourTeamTitle = "ourTeam-module--ourTeamTitle--VRu6D";
export var ourTeamSubhead = "ourTeam-module--ourTeamSubhead--+D43n";
export var bgWhite = "ourTeam-module--bgWhite--P+lv0";
export var bgGrey = "ourTeam-module--bgGrey--b5W6E";
export var container = "ourTeam-module--container--KcUZo";
export var sectionHeading = "ourTeam-module--sectionHeading--0M4e8";
export var black = "ourTeam-module--black--odhul";
export var white = "ourTeam-module--white--94XEr";
export var bioImage = "ourTeam-module--bioImage--oCo5n";
export var staffName = "ourTeam-module--staffName--LbWfL";
export var staffPosition = "ourTeam-module--staffPosition--6kZCG";
export var socialWrap = "ourTeam-module--socialWrap--GuF2F";
export var primaryStaff = "ourTeam-module--primaryStaff--i0e67";
export var primaryItem = "ourTeam-module--primaryItem--w4gBE";
export var advisorStaffInset = "ourTeam-module--advisorStaffInset--N4W1n";
export var advisorItem = "ourTeam-module--advisorItem--Zw+1T";
export var fellowsStaffInset = "ourTeam-module--fellowsStaffInset--1bloC";
export var fellowItem = "ourTeam-module--fellowItem--bc3Qh";
export var directorsStaffInset = "ourTeam-module--directorsStaffInset--3IJDJ";
export var directorItem = "ourTeam-module--directorItem--N1M6h";
export var projectsCallout = "ourTeam-module--projectsCallout--AqgeG";
export var projectsCalloutImage = "ourTeam-module--projectsCalloutImage--vrEWe";
export var projectsCalloutHeading = "ourTeam-module--projectsCalloutHeading--3dO+u";
export var projectsButtonWrap = "ourTeam-module--projectsButtonWrap--S+AK7";
export var textLine = "ourTeam-module--textLine--rk8+X";
export var xWhite = "ourTeam-module--xWhite--fAXKZ";
export var xBlack = "ourTeam-module--xBlack--iPYGV";
export var slideUp = "ourTeam-module--slideUp--T1hr6";
export var slideUpLarge = "ourTeam-module--slideUpLarge---0ck-";