import React from 'react';

const LocationPin = () => (
  <div className="locationPin">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 22">
      <circle
        cx="8.5"
        cy="8.5"
        r="4"
        style={{
          fill: 'none',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M255,197.5c0,6-8,13-8,13s-8-7-8-13a8,8,0,0,1,16,0Z"
        transform="translate(-238.5 -189)"
        style={{
          fill: 'none',
          strokeLinejoin: 'round',
        }}
      />
    </svg>
  </div>
);

export default LocationPin;
