import React from 'react';
import PropTypes from 'prop-types';
import * as style from './roundedButton.module.scss';

class RoundedButton extends React.Component {
	constructor(props) {
		super(props);

		// To add a color, add a case here and add a style class in the scss module.
		switch(props.color) {
			default:
			case 'orange':
				this.colorStyle = style.orange;
				break;
		}
	}

	render() {
		return (
			<button
				className={`${style.base} ${this.colorStyle} ${this.props.centered ? style.centered : ''}`}
				type={this.props.type}
				onClick={this.props.onClick}
				data-url={this.props.url}>
				{this.props.text}
			</button>
		)
	}
}

RoundedButton.propTypes = {
	onClick: PropTypes.func.isRequired,
	type: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	color: PropTypes.string,
};

export default RoundedButton;