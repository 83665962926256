import React, { Component } from 'react';
import * as style from './sectionNavigation.module.scss';
import { makeAnchor } from '../article/faqList';
import jump from 'jump.js';

class SectionNavigation extends Component {
	constructor(props) {
		super(props);
		const currentSection = (props.currentHash) ? props.currentHash.substring(1) : '';
		this.state = {
			currentSection: currentSection, 
		};
		this.handleScroll = this.handleScroll.bind(this);
		this.handleResize = this.handleResize.bind(this);
	}
	
	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll);
		window.addEventListener('resize', this.handleResize);
	}
	
	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
		window.removeEventListener('resize', this.handleResize);
	}
	
	handleScroll() {
		this.setActiveSection();
	}
	
	handleResize() {
		this.setActiveSection();
	}
	
	setActiveSection() {
		var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
		var activeSectionSet = false;
		this.props.sections.forEach((section, i) => {
			var sectionElem = document.getElementById(makeAnchor(section.section_heading)).getBoundingClientRect();
			if(sectionElem.y <= 70) {
				activeSectionSet = true;
				this.setState({ currentSection: section.section_heading });
			}
		});
		if(!activeSectionSet) {
			this.setState({ currentSection: '' });
		}
	}
	
	render() {
		const linkClick = (e, sectionId) => {
			e.preventDefault();
			e.stopPropagation();
			jump(`#${makeAnchor(sectionId)}`);
			this.setState({ currentSection: sectionId });
			setTimeout(() => {
				window.location.hash = makeAnchor(sectionId);
			}, "1000");
		};

		return (
			<div className={`section-navigation ${style.wrapper}`}>
				{this.props.sections.map((section, i) => {
					return(
						<a
							onClick={(e) => linkClick(e, section.section_heading)}
							className={`${this.state.currentSection === section.section_heading ? style.activeLink : ''}`}
							key={`sectionNavigation${i}`}
							href={`#${makeAnchor(section.section_heading)}`}
						>
							<div className={style.link}>
								<div className={style.circle} />
								<div className={style.linkText}>{section.section_heading}</div>
							</div>
						</a>
					);
				})}
			</div>
		);
	}
}

export default SectionNavigation;
